/* eslint-disable max-len */
import React, { useState, useEffect, useContext } from 'react';
import { func, string } from 'prop-types';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { ExperienceContext, useStore, useConfigService } from '@thd-nucleus/experience-context';
import { FBTProvider } from './contexts/FBTContext';
import { fbtRecsDataModel } from './dataModel';
import { RecsFBT } from './RecsFBT/RecsFBT.component';
import { FBTPlaceholder } from './FBTPlaceholder.component';
import './thd-recs-fbt.module.scss';
import { DtsTntVariables, UseRecsCxtAndProps } from './helpers/tnt-variables';

const FrequentlyBoughtTogether = (props) => {
  const { itemId, originalConfigId, onLoadDynamicRecs, apiName } = props;
  const [mounted, setMounted] = useState(false);
  const abcRecsTestEnable = useConfigService('fs:isABCRecsTestEnable');
  const [tntData, setTntData] = useState(null);
  const { channel, isConsumerApp } = useContext(ExperienceContext);
  const storeInfo = useStore();

  useEffect(() => {
    setMounted(true);
    if (abcRecsTestEnable) {
      if (typeof LIFE_CYCLE_EVENT_BUS !== 'undefined') {
        LIFE_CYCLE_EVENT_BUS.lifeCycle.on('recs.tnt', (data) => {
          let tntObj = {};
          const output = data?.output;
          if (output?.currentAPI === apiName) {
            if (output?.isDts) {
              tntObj.isDts = output?.isDts;
              tntObj.isTNT = data?.eventName === 'tnt';
              tntObj.schemaName = output?.dtsModel?.model;
              tntObj.dtsModelVariables = output?.dtsModel;
              tntObj.tntTitle = output?.dtsModel?.tntTitle;
              tntObj.tntTitleCustomerType = output?.dtsModel?.tntTitleCustomerType;
              setTntData(tntObj);
            } else {
              tntObj.isDts = output?.isDts;
              tntObj.isTNT = data?.eventName === 'tnt';
              tntObj.schemaName = output?.recsModel?.testAPI;
              tntObj.recsModelVariables = output?.recsModel;
              tntObj.tntTitle = output?.recsModel?.tntTitle;
              tntObj.tntTitleCustomerType = output?.recsModel?.tntTitleCustomerType;
              setTntData(tntObj);
            }
          }
        });
      }
    }
  }, []);

  if (mounted) {
    const queryProps = {};
    if (abcRecsTestEnable && tntData?.isTNT && tntData?.isDts) {
      const { qName, dtsopts, dtsAnchor } = DtsTntVariables(tntData?.dtsModelVariables, props, storeInfo, channel, isConsumerApp);
      queryProps.qName = qName;
      queryProps.vars = dtsopts;
      queryProps.anchor = dtsAnchor;
    } else if (abcRecsTestEnable && tntData?.isTNT && (tntData?.isDts !== undefined) && !tntData?.isDts) {
      const { qName, recsOpts, recsAnchor } = UseRecsCxtAndProps(tntData?.recsModelVariables, props, storeInfo, channel, isConsumerApp);
      queryProps.qName = qName;
      queryProps.vars = recsOpts;
      queryProps.anchor = recsAnchor;
    }
    return (
      <FBTProvider
        itemId={itemId}
        originalConfigId={originalConfigId}
        apiName={apiName}
        onLoadDynamicRecs={onLoadDynamicRecs}
        tntData={tntData}
        queryProps={queryProps}
      >
        <RecsFBT />
      </FBTProvider>
    );
  }
  return null;
};

FrequentlyBoughtTogether.displayName = 'ThdRecsFbt';

const propTypes = {
  itemId: string.isRequired,
  originalConfigId: string,
  apiName: string,
  onLoadDynamicRecs: func
};
const defaultProps = {
  onLoadDynamicRecs: null,
  originalConfigId: null,
  apiName: 'fbt'
};
FrequentlyBoughtTogether.propTypes = propTypes;
FrequentlyBoughtTogether.defaultProps = defaultProps;
FrequentlyBoughtTogether.dataModel = fbtRecsDataModel;
const HydratedFBT = withHydrator({
  delay: 1500,
  id: 'product-section-fbt',
  placeholder: (<FBTPlaceholder />),
  preserveCtxVal: 'clientStore',
  scrollBuffer: 0
}, FrequentlyBoughtTogether);

const ThdRecsFbt = withErrorBoundary(HydratedFBT);

ThdRecsFbt.propTypes = propTypes;
ThdRecsFbt.defaultProps = defaultProps;
// dataModels should be defined on the component and extended up, not at a global component level
ThdRecsFbt.dataModel = fbtRecsDataModel;
ThdRecsFbt.displayName = FrequentlyBoughtTogether.displayName;

export { ThdRecsFbt };

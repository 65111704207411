import { string, func, oneOfType, number } from 'prop-types';

export const propTypes = {
  itemId: string.isRequired,
  onClick: func,
  onHover: func,
  productType: string,
};

export const defaultProps = {
  onClick: null,
  onHover: null,
  productType: 'Products',
};

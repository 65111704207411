export const getImageURL = (images) => {
  if (!images || images?.length === 0) return null;
  const primary = images.find((img) => img.subType === 'PRIMARY');
  if (!primary) return null;
  return primary.url;
};

export const getFinalPriceMessageCount = (data) => {
  const currentCheckedItems = data?.filter((item) => item.checked);
  return currentCheckedItems?.filter(
    (item) => (item?.pricing?.mapScenario || item?.pricing?.message === 'see final price in cart')).length;
};

export const getMapAboveOriginal = (items = []) => {
  const messageAndQuantity = {
    mapAboveOriginalPrice: false,
    quantityLimit: 0
  };
  items.map((item) => {
    if (!item.checked) return messageAndQuantity;
    if (item?.pricing?.message === 'see final price in cart' && item?.pricing?.mapAboveOriginalPrice) {
      messageAndQuantity.mapAboveOriginalPrice = true;
    }
    if (item?.product?.info?.quantityLimit > 0) {
      messageAndQuantity.quantityLimit = item?.product?.info?.quantityLimit;
    }
    return messageAndQuantity;
  });
  return messageAndQuantity;
};

export const getTotalPrice = (items = []) => {
  const currentCheckedItems = items.filter((item) => item.checked);
  const hidePromotionDetails = currentCheckedItems.some((item) => !item?.pricing?.original);
  let originalPriceTotal = '';
  let dollarOffTotal = '';
  let percentageOffTotal = '';

  if (!hidePromotionDetails) {
    // originalPrice total
    originalPriceTotal = currentCheckedItems.reduce((acc, item) => {
      return acc + (item?.pricing?.original || item?.pricing?.value);
    }, 0);
    originalPriceTotal = parseFloat(originalPriceTotal.toFixed(2));
    // dollarOff total
    dollarOffTotal = currentCheckedItems.reduce((acc, item) => {
      const dollarOff = item?.pricing?.promotion?.dollarOff
        ? item?.pricing?.promotion?.dollarOff
        : 0;
      return acc + dollarOff;
    }, 0);
    dollarOffTotal = parseFloat(dollarOffTotal.toFixed(2));
    // percentageOff total
    percentageOffTotal = originalPriceTotal === 0
      ? 100
      : (dollarOffTotal / originalPriceTotal) * 100;
    percentageOffTotal = percentageOffTotal.toFixed();
  }

  // specialPrice total
  let valuePriceTotal = currentCheckedItems.reduce((acc, item) => {
    return acc + item?.pricing?.value;
  }, 0);
  valuePriceTotal = parseFloat(valuePriceTotal.toFixed(2));
  // pricingMessage
  let pricingMessage = currentCheckedItems.reduce((acc, item) => {
    if (item?.pricing?.message) {
      return {
        message: item?.pricing?.message,
        mapDetail: item?.pricing?.mapDetail,
      };
    }
    return acc;
  }, {});
  const seeFinalPriceMsgCount = getFinalPriceMessageCount(currentCheckedItems);
  if (seeFinalPriceMsgCount > 0) {
    pricingMessage.message = 'see final price in cart';
  }

  return {
    pricing: {
      original: originalPriceTotal,
      promotion: {
        dollarOff: dollarOffTotal,
        percentageOff: percentageOffTotal,
      },
      ...(!pricingMessage?.message && { value: valuePriceTotal }),
      // value: valuePriceTotal,
      message: pricingMessage?.message || '',
      mapDetail: pricingMessage?.mapDetail,
      mapAboveOriginalPrice: getMapAboveOriginal(items).mapAboveOriginalPrice,
      quantityLimit: getMapAboveOriginal(items).quantityLimit
    }
  };
};

export const getCart = (items = [], channel) => {
  const options = {
    host: typeof window !== 'undefined'
      ? window.location.origin
      : null,
    channel,
    paypal: false,
    misship: false,
    directCheckout: true,
    alterBrowserHistory: true
  };
  const itemDetails = items?.filter((item) => item.checked)
    .map((item) => {
      if (item.type === 'primary' && item.originalConfigId) {
        return {
          itemId: String(item.itemId),
          paintConfiguration: { configurationId: item.originalConfigId },
          quantity: 1
        };
      }
      return {
        itemId: String(item.itemId),
        quantity: 1
      };
    });

  return {
    itemDetails,
    options
  };
};

export const getAddToCartMessage = (selectedItemsCount) => {
  const message = {
    isDisable: false,
    atcMessage: 'Add to cart'
  };
  if (selectedItemsCount === 1) {
    message.priceMessage = 'Subtotal:';
    message.atcMessage = 'Add to Cart';
  } else if (selectedItemsCount === 0) {
    message.priceMessage = '';
    message.isDisable = true;
  } else {
    message.priceMessage = 'Subtotal:';
    message.atcMessage = `Add ${selectedItemsCount} Items to Cart`;
  }
  return message;
};

export const getAppId = (device, app) => {
  let appId = '';
  if (app) {
    appId = 'consumerApp';
  } else if (device === 'mobile') {
    appId = 'mobileweb';
  } else {
    appId = 'desktop';
  }
  return appId;
};

export const isRecsLoading = (dataState, data, fallBackRecsData, loading, fallBackRecsLoading, tntTest) => {
  if (tntTest) {
    if (loading || fallBackRecsLoading || (!dataState && !!data?.recs)
      || (!dataState && !data?.recs && (!!fallBackRecsData?.recs || fallBackRecsData === undefined))
    || (!dataState && data === undefined && fallBackRecsData === undefined)) {
      return true;
    }
  } else if (loading || (!dataState && !!data?.recs)) {
    return true;
  }

  return false;
};

export const publish = (eventObj) => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.LIFE_CYCLE_EVENT_BUS.trigger(
      `${eventObj.componentName}.${eventObj.eventName}`,
      eventObj.data
    );
  }
};

export const titleBuilder = (isTnt, customerType, tntTitleCustomerType, tntTitle) => {
  if (isTnt && tntTitle && (tntTitleCustomerType === customerType || (typeof tntTitleCustomerType === 'undefined'))) {
    return tntTitle;
  }
  return customerType === 'b2b' ? 'Pros Buy These Together' : 'Frequently Bought Together';
};
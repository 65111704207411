/* eslint-disable max-len */
import React, {
  createContext, useContext, useRef, useEffect, useState, useCallback
} from 'react';
import {
  element, func, string, oneOfType, array, object
} from 'prop-types';

import { useDataModel, useLazyDataModel } from '@thd-nucleus/data-sources';
import { useStore, ExperienceContext } from '@thd-nucleus/experience-context';
import { fbtRecsDataModel } from '../dataModel';
import { isRecsLoading, publish, titleBuilder } from '../helpers/helpers';

const isFulfillable = (product) => {
  return !!(product?.fulfillment?.fulfillmentOptions || []).find((option) => option.fulfillable);
};

const isDiscontinued = (product) => {
  return !!(product?.availabilityType?.discontinued);
};

const getNearByStore = (fulfillment) => {
  const locations = fulfillment?.locations || [];
  if (locations.length > 1) {
    const nearbyStore = locations.find((location) => !location.isAnchor);
    return nearbyStore;
  }
  return null;
};

const getLocalStore = (fulfillment) => {
  const locations = fulfillment?.locations || [];
  const localStore = locations.find((location) => location.isAnchor);
  return localStore;
};

const isBOPISHaveQuantity = (product) => {
  const pickupService = (product?.fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'pickup');
  const bopisFulfillment = (pickupService?.services || []).find((service) => service.type === 'bopis');
  if (!bopisFulfillment) {
    return false;
  }
  const nearByStoreLocation = getNearByStore(bopisFulfillment);
  const localStoreLocation = getLocalStore(bopisFulfillment);
  return pickupService?.fulfillable
        && (localStoreLocation?.inventory?.quantity > 0 || nearByStoreLocation?.inventory?.quantity > 0);
};

const isDisplayableBasedOnFulfillment = (product) => {
  const deliveryService = (product?.fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'delivery');
  const pickupService = (product?.fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'pickup');
  const shippingFulfillment = (deliveryService?.services || []).find((service) => service.type === 'sth');
  const bossFulfillment = (pickupService?.services || []).find((service) => service.type === 'boss');
  return !!(!isDiscontinued(product)
        && (deliveryService
        || shippingFulfillment
        || bossFulfillment
        || isBOPISHaveQuantity(product?.fulfillment)));
};
export const FBTContext = createContext({});
export const useFBTContext = () => useContext(FBTContext);

export const FBTProvider = ({
  itemId,
  originalConfigId,
  onChange,
  apiName,
  onLoadDynamicRecs,
  children,
  tntData,
  queryProps
}) => {
  const { storeId, storeZip: zipCode, membershipInformation } = useStore();
  const { customer } = useContext(ExperienceContext);
  const isFulfillableRef = useRef(true);
  const isDisplayableRef = useRef(true);

  const clientProductResponse = useDataModel('clientOnlyProduct', {
    variables: {
      itemId,
      storeId,
      loyaltyMembershipInput: membershipInformation?.data?.loyaltyMembership || null,
    },
    ssr: false
  });

  const recsopts = {
    variables: {
      anchorId: itemId,
      storeId,
      zipCode: zipCode || null,
      apiName,
      loyaltyMembershipInput: membershipInformation?.data?.loyaltyMembership || null,
      skipInstallServices: true,
      dataSource: apiName
    },
    ssr: false
  };

  const [renderData, setRenderData] = useState(null);
  const isDtsRef = useRef(null);
  const recsSchema = useRef(tntData?.schemaName ? tntData?.schemaName : apiName);
  const [containerTitle, setContainerTitle] = useState(null);
  const opts = (tntData?.isTNT !== undefined && tntData?.isTNT) ? queryProps?.vars?.opts : recsopts;
  const query = queryProps?.qName || 'recs';

  const {
    data, loading, error
  } = useDataModel(query, opts);

  const [
    getInitialRecsContainer,
    {
      data: fallBackRecsData,
      loading: fallBackRecsLoading,
      error: fallBackRecsError,
    }
  ] = useLazyDataModel('recs', recsopts);

  const productsCnt = tntData?.isDts ? data?.dts?.products?.length : data?.recs?.products?.length;
  const productsData = tntData?.isDts ? data?.dts : data?.recs;

  const shouldRequestFallback = () => {
    return (tntData?.isTNT && storeId !== '8119' && !loading && (error || !productsCnt > 0));
  };
  const productResponse = useDataModel('product', {
    variables: {
      itemId,
      storeId,
      loyaltyMembershipInput: membershipInformation?.data?.loyaltyMembership || null,
    },
    skip: (storeId !== '8119')
  });

  const loadedProduct = useRef(productResponse);

  const [checkedItems, setCheckedItems] = useState([itemId] || []);

  const triggerTntAnalytics = (fallBackTrue) => {
    publish({
      componentName: 'recs-tnt',
      eventName: 'fallback-analytics',
      data: {
        testApiValue: recsSchema.current,
        fallbackValue: fallBackTrue || 'f',
        errorValue: (typeof error === 'undefined') ? 'n' : 'y',
        productsCntValue: productsCnt || 0
      }
    });
  };
  useEffect(() => {
    if (!loading && productsCnt > 0) {
      if (tntData?.isTNT) {
        triggerTntAnalytics();
      }
      setRenderData(productsData);
      setContainerTitle(titleBuilder(tntData?.isTNT, customer?.type, tntData?.tntTitleCustomerType, tntData?.tntTitle));
      recsSchema.current = tntData?.schemaName ? tntData?.schemaName : apiName;
      isDtsRef.current = query === 'dts';
    } else if (!loading && shouldRequestFallback()) {
      getInitialRecsContainer();
    }
  }, [data, loading]);

  useEffect(() => {
    if (!fallBackRecsLoading && !fallBackRecsError && fallBackRecsData?.recs?.products?.length > 0) {
      triggerTntAnalytics('t');
      setRenderData(fallBackRecsData?.recs);
      setContainerTitle(titleBuilder(false, customer?.type));
      isDtsRef.current = false;
      recsSchema.current = apiName;
    }
  }, [fallBackRecsData, fallBackRecsLoading]);

  const handleDynamicRecsLoad = useCallback((resp) => {
    const products = isDtsRef.current ? resp?.dts?.products : resp?.recs?.products;
    if (products?.length) {
      const additionalIds = (products.map(({ product }) => product?.identifiers?.itemId).filter((val) => val));
      setCheckedItems([itemId, ...additionalIds]);
    }
    if (onLoadDynamicRecs) {
      onLoadDynamicRecs(resp);
    }
  }, [itemId, onLoadDynamicRecs]);

  useEffect(() => {
    if (onChange && !loading && !fallBackRecsLoading && fallBackRecsData?.recs?.products?.length > 0) {
      handleDynamicRecsLoad(fallBackRecsData);
    } else {
      handleDynamicRecsLoad(data);
    }
  }, [itemId, data, onChange, fallBackRecsData]);

  if (!tntData?.isTNT && !loading && (error || !data?.recs)) {
    return null;
  }

  if (fallBackRecsError && !fallBackRecsLoading) {
    return null;
  }

  const clientProductComplete = clientProductResponse.data
        && !clientProductResponse.loading
        && clientProductResponse.called;

  if (productResponse.data && !productResponse.loading && productResponse.called) {
    loadedProduct.current = productResponse;
  }

  if (clientProductComplete) {
    loadedProduct.current = clientProductResponse;
  }

  if (clientProductResponse.data?.product?.fulfillment) {
    const isProductFulfillable = isFulfillable(clientProductResponse.data?.product);
    isFulfillableRef.current = isProductFulfillable;
  } else if (clientProductResponse.data?.product) {
    isFulfillableRef.current = false;
  }

  if (clientProductResponse.data?.product?.availabilityType && clientProductResponse.data?.product?.fulfillment) {
    const isFbtDisplayable = isDisplayableBasedOnFulfillment(clientProductResponse.data?.product);
    isDisplayableRef.current = isFbtDisplayable;
  }

  const contextValue = {
    RecsFbtResponseData: renderData,
    RecsFbtResponseDataLoading: isRecsLoading(renderData, data, fallBackRecsData, loading, fallBackRecsLoading, tntData?.isTNT),
    isFulfillable: isFulfillableRef.current,
    isDisplayableBasedOnFulfillment: isDisplayableRef.current,
    productResponseData: loadedProduct.current.data,
    originalConfigId,
    productResponseLoading: loadedProduct.current.loading,
    checkedItems,
    setCheckedItems,
    apiName: recsSchema.current,
    containerTitle
  };

  return (
    <FBTContext.Provider value={contextValue}>
      {children}
    </FBTContext.Provider>
  );
};

FBTProvider.propTypes = {
  itemId: string.isRequired,
  originalConfigId: string,
  apiName: string.isRequired,
  onChange: func,
  onLoadDynamicRecs: func,
  children: element.isRequired,
  tntData: oneOfType([array, object]),
  queryProps: oneOfType([array, object])
};

FBTProvider.dataModel = fbtRecsDataModel;
FBTProvider.defaultProps = {
  originalConfigId: null,
  onChange: () => {},
  onLoadDynamicRecs: () => {},
  tntData: null,
  queryProps: null
};
